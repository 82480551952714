<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import EmptyList from "@/components/widgets/empty_list"
import EmailConfig from "@/components/journeys/configs/email-config"
import SmsConfig from "@/components/journeys/configs/sms-config"
import AppPushConfig from "@/components/journeys/configs/app-push-config"
import WebPushConfig from "@/components/journeys/configs/web-push-config"


import {
	audienceMethods,
  broadcastMethods,
  projectMethods,
  goalMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Broadcast",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect, EmptyList, EmailConfig, SmsConfig, AppPushConfig, WebPushConfig },
  data() {
    return {
      title: '',
      items: [],
      broadcast:{
        audience:{
          type: 'all',
        },
        variants:[],
        settings: {
          test_ratio: 10,
          testing_duration: 1
        },
        goal: {
          _id: 'purchase',
          time : 24
        }
      },
      submitted: false, 
      auxAudience: null,
      audiences:[],
      auxContactList: null,
      contactLists:[],
      empty_config:{
        "title" : this.$t('broadcasts.variants_empty'),
        "subtitle" :this.$t('broadcasts.variants_empty_subtitle'),
        "buttonText" : this.$t('broadcasts.variants_new'),
        icon: "fa-cubes"
      },
      showVariant:false,
      variant: {
        config: {}
      },
      times:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      interactions: [
        { id: 'purchase',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_purchase')},
        { id: 'cart',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_cart')},
        { id: 'like',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_like')},
        { id: 'rate',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_rate')},
        { id: 'view',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_view')},
      ],
      auxInteraction: { id: 'view',label : this.$t('journeys.journey_workflow_components_trigger_type_interaction_view')},
      audience_size: 0,
      totalCount:0,
      goals: [
        { _id: 'none',name : this.$t('goals.none')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
        { _id: 'notification-opened',name : this.$t('goals.notification-opened')},
      ],
      auxGoal: { _id: 'notification-opened',name : this.$t('goals.notification-opened')},
      auxDay: 1,
      auxHour: 0,
      timeError: false,
      channel: null,
      auxExcludeAudience: null,
      isCustomerCountLoading: false
    };
  },
  validations: {
    broadcast: {
        name: { required},
        description: { required},
        type: { required},
        audience: {required},
    }
  },
  mounted(){
    const broadcastId = this.$route.query?.id;
    if(!broadcastId)
      this.loadAudiences();
    this.loadContactLists();
    
    if(broadcastId){
      this.loadCustomGoals(true);
      this.title = ''
      let loader = this.$loading.show();
      this.getBroadcast(broadcastId).then((res)=>{
        this.broadcast = res.data;
        this.loadAudiences();
        this.channel = this.broadcast.type;
        this.auxAudience = this.broadcast.audience.type == 'audience' && this.broadcast.audience.audience && this.audiences.filter(a=> a._id == this.broadcast.audience.audience ).length > 0  ? this.audiences.filter(a=> a._id == this.broadcast.audience.audience)[0] : this.auxAudience;
        this.auxContactList = this.broadcast.audience.type == 'list' && this.broadcast.audience.contactList && this.contactLists.filter(a=> a._id == this.broadcast.audience.contactList ).length > 0  ? this.contactLists.filter(a=> a._id == this.broadcast.audience.contactList)[0] : this.auxContactList;
        this.auxInteraction = this.broadcast?.settings?.interaction &&  this.interactions.filter(i=> i.id == this.broadcast?.settings?.interaction).length>0 ? this.interactions.filter(a=> a.id == this.broadcast?.settings?.interaction)[0] : this.auxInteraction
        this.auxGoal = this.broadcast?.settings?.goal && this.goals.filter(i=> i.id == this.broadcast?.settings?.goal).length>0 ? this.goals.filter(a=> a.id == this.broadcast?.settings?.goal)[0] : this.auxGoal
        this.title = `${res.data.name} - ${this.channel}`
        this.broadcast.settings.testing_duration = this.broadcast.settings?.testing_duration ? this.broadcast.settings?.testing_duration : 1

        if(this.broadcast.goal){
          this.auxGoal = this.broadcast?.goal?._id && this.goals.filter(i=> i._id == this.broadcast?.goal?._id).length>0 ? this.goals.filter(a=> a._id == this.broadcast?.goal?._id)[0] : this.auxGoal

          if(this.broadcast.goal.time){
            this.auxDay = Math.floor(this.broadcast.goal.time / 24)
            this.auxHour = this.broadcast.goal.time % 24
          }
        }else{
          this.broadcast.goal = this.auxGoal
        }
        if(this.broadcast.status== 'draft')
          this.loadAllCustomersCount();

      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.get_broadcast_error'),title: this.$t('broadcasts.title')});
      }).finally(()=>{
        loader.hide();
      })
    }else{
      this.loadCustomGoals(false);
      this.channel = this.$route.query?.channel;
      this.broadcast.type = this.$route.query?.channel;
      this.title = this.$t('broadcasts.new_title').replace('##',`${this.$t(`broadcasts.channel_${this.channel}`)}`)
    }
    
  },
  computed:{
    
  },
  methods:{
    ...audienceMethods,
    ...broadcastMethods,
    ...projectMethods,
    ...goalMethods,

    
    
    loadCustomGoals(isUpdate){
      const params={
        q: `project=${localStorage.getItem('current_project')}&limit=50`,
      }
      this.getGoals(params).then((res)=>{
        this.goals = this.goals.concat(res.data);
        if(isUpdate){
          this.auxGoal = this.goals.filter(g=>g._id == this.broadcast.goal._id).length > 0 ? this.goals.filter(g=>g._id == this.broadcast.goal._id)[0] : this.auxGoal;
        }
      });
    },

    loadAllCustomersCount(){
      this.isCustomerCountLoading = true;
      const payload = {
        channel: this.channel == 'app_push' ? 'push' :this.channel,
        project: localStorage.getItem('current_project'),
        include_audience : [],
        exclude_audience: []
      }

      this.countCustomerBroadcast(payload).then((res)=>{
        this.totalCount = res.data?.count;
        this.audience_size = this.totalCount;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('customers.get_customers_error'),title:  this.$t('customers.title') });
      }).finally(()=>{
        this.isCustomerCountLoading = false;
        this.isLoading = false;
      })
    },

    loadAudiences(){
      
      let loader = this.$loading.show();
      const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=100`
			}
			this.getAudiences(params).then((audiences)=>{
        if(audiences&& audiences.data){
          this.audiences= audiences.data;
          this.auxAudience = this.broadcast.audience.type == 'audience' && this.broadcast.audience.audience && this.audiences.filter(a=> a._id == this.broadcast.audience.audience ).length > 0  ? this.audiences.filter(a=> a._id == this.broadcast.audience.audience)[0] : this.auxAudience;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("audiences.get_audiences_error"),title:  this.$t("audiences.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    
    },
    loadContactLists(){
      
      let loader = this.$loading.show();
      const params = {
				q : `where[project]=${localStorage.getItem('current_project')}&limit=100`
			}
			this.getContactLists(params).then((lists)=>{
        if(lists&& lists.data){
          this.contactLists= lists.data;
          this.auxContactList = this.broadcast.audience.type == 'list' && this.broadcast.audience.contactList && this.contactLists.filter(a=> a._id == this.broadcast.audience.contactList ).length > 0  ? this.contactLists.filter(a=> a._id == this.broadcast.audience.contactList)[0] : this.auxContactList;
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("contact_lists.get_contact_lists_error"),title:  this.$t("contact_lists.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    
    },
    onAudienceTypeSelected(type){
      this.broadcast.audience.type = type;
      if(type=='all'){
        this.auxAudience = null;
        this.auxContactList = null;
      }
      this.onChangeReceptorsCount();
      
    },
    onCloneVariantClicked(variant){
      let v = Object.assign({}, variant);
      v.name = `Variant ${this.broadcast.variants.length + 1}`;
      delete v._id;

      this.broadcast.variants.push(v);
    },
    onRemoveVariantClicked(index){
      this.broadcast.variants.splice(index,1);
    },
    onEditVariantClicked(variant){
      this.isUpdate = true;
      this.variant = variant;
        
      this.showVariant = true; 
        
      const loader = this.$loading.show();
      setTimeout(() => {
        if(this.channel == 'email'){
          this.$refs.emailConfigComponent.setEmailConfig(this.variant.templateValue);
        }else if(this.channel == 'app_push'){
          this.$refs.appPushConfigComponent.setAppPushNotificationsConfig(this.variant.templateValue);
        }
        else if(this.channel == 'web_push'){
          this.$refs.webPushConfigComponent.setWebPushNotificationsConfig(this.variant.templateValue);
        }else{
          this.$refs.smsConfigComponent.setSMSConfig(this.variant.templateValue);
        }
        loader.hide();
      }, 1000);
    },
    onNewVariantClicked(){
      this.isUpdate = false;
      this.variant.name = `Variant ${this.broadcast.variants.length + 1}`,
      this.showVariant = true;
    },
    onConfirmVariantClicked(){

      if(!this.variant.config)
        this.variant.config = {};

      if(this.channel == 'email'){
        if(!this.$refs.emailConfigComponent.validateEmailConfig())
          return;
        this.variant.templateValue = this.$refs.emailConfigComponent.getEmailConfig()
      }
      else if(this.channel == 'app_push'){
        if(!this.$refs.appPushConfigComponent.validatePushNotificationConfig())
          return;
        this.variant.templateValue = this.$refs.appPushConfigComponent.getAppPushNotificationsConfig();
      }
      else if(this.channel == 'web_push'){
        if(!this.$refs.webPushConfigComponent.validatePushNotificationConfig())
          return;
        this.variant.templateValue = this.$refs.webPushConfigComponent.getWebPushNotificationsConfig();
      }else{
        if(!this.$refs.smsConfigComponent.validateSMSConfig())
          return;
        this.variant.templateValue = this.$refs.smsConfigComponent.getSMSConfig()
      }

      if(!this.isUpdate){
        this.broadcast.variants.push(this.variant);
      }
      
      this.showVariant = false;
      this.isUpdate = false;
      this.variant = {
        config: {}
      }
    },
    onConfirmClicked(){
      this.submitted = true;
        
      this.$v.$touch();

      if (this.$v.$invalid || this.timeError) {
				return;
			}

      if(this.broadcast._id){
        if(!this.broadcast.goal.time)
          this.broadcast.goal.time = 24;
        let loader = this.$loading.show();
        this.updateBroadcast(this.broadcast).then(()=>{
          this.$notify({ type: 'success', text: this.$t('broadcasts.update_broadcast_success'),title:  this.$t('broadcasts.title') });
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('broadcasts.update_broadcast_error'),title:  this.$t('broadcasts.title') });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        let loader = this.$loading.show();
        this.broadcast.project = localStorage.getItem('current_project');
        this.broadcast.status = 'draft';
        
        if(!this.broadcast.goal.time)
          this.broadcast.goal.time = 24;

        this.createBroadcast(this.broadcast).then(()=>{
          this.$notify({ type: 'success', text: this.$t('broadcasts.create_broadcast_success'),title:  this.$t('broadcasts.title') });
          this.$router.push({
              path: "/broadcasts",
              }
          ).catch(() => {});
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('broadcasts.create_broadcast_success'),title:  this.$t('broadcasts.title') });
      }).finally(()=>{
        loader.hide();
      })
      }
    },
    onCancelClicked(){
      this.$router.push(
				{
					path: "/broadcasts",
				}
			).catch(() => {});
    },
    onAudienceSelected(){
      this.broadcast.audience.contactList = null;
      this.broadcast.audience.audience = this.auxAudience._id;
      this.onChangeReceptorsCount();
    },
    onChangeReceptorsCount(){
      const payload = {
        channel: this.channel == 'app_push' ? 'push' :this.channel,
        project: localStorage.getItem('current_project'),
      }

      if(this.broadcast.audience.audience){
        payload.include_audience = [this.broadcast.audience.audience]
      }else if(this.broadcast.audience.contactList){
        payload.include_contact_list = [this.broadcast.audience.contactList]
      }else{
        payload.include_audience = []
      }
      if(this.broadcast.excludeAudience?.audience){
        payload.exclude_audience = [this.broadcast.excludeAudience.audience];
      }else{
        payload.exclude_audience = [];
      }

      this.isCustomerCountLoading = true;
      this.countCustomerBroadcast(payload).then((res)=>{
        this.audience_size = res.data?.count;
      }).finally(()=>{
        this.isCustomerCountLoading = false;
      });
    },
    onExlcudeAudienceSelected(){
      if(this.auxExcludeAudience){
        if(!this.broadcast.excludeAudience) this.broadcast.excludeAudience = {}
        this.broadcast.excludeAudience.audience = this.auxExcludeAudience?._id || null;
      }else{
        this.broadcast.excludeAudience = null;
      }
      this.onChangeReceptorsCount();
    },
    onContactListSelected(){
      this.broadcast.audience.audience = null;
      this.broadcast.audience.contactList = this.auxContactList._id;
      this.onChangeReceptorsCount();
    },
    onInteractioneSelected(){
      this.broadcast.settings.interaction = this.auxInteraction.id;
    },
    onGoalSelected(){
      this.broadcast.settings.goal = this.auxGoal._id;
      this.broadcast.goal._id = this.auxGoal._id;
    },
    onCancelVariantClicked(){
      this.showVariant = false
    },
    onTestMessageClicked(){

      if(!this.variant.config)
        this.variant.config = {};

      if(this.channel == 'email'){
        if(!this.$refs.emailConfigComponent.validateEmailConfig())
          return;
          this.$refs.emailConfigComponent.showTestChannel();
        //this.variant.templateValue = this.$refs.emailConfigComponent.getEmailConfig()
      }
      else if(this.channel == 'app_push'){
        if(!this.$refs.appPushConfigComponent.validatePushNotificationConfig())
          return;
          this.$refs.appPushConfigComponent.showTestChannel();
        //this.variant.templateValue = this.$refs.appPushConfigComponent.getAppPushNotificationsConfig();
      }
      else if(this.channel == 'web_push'){
        if(!this.$refs.webPushConfigComponent.validatePushNotificationConfig())
          return;
          this.$refs.webPushConfigComponent.showTestChannel();
      }else{
        if(!this.$refs.smsConfigComponent.validateSMSConfig())
          return;
          this.$refs.smsConfigComponent.showTestChannel();
        //this.variant.templateValue = this.$refs.smsConfigComponent.getSMSConfig()
      }
    
      
    },
    
    onTimeChanged(){
      if(!this.auxDay)
        this.auxDay = 1;

      if(!this.auxHour)
        this.auxHour = 0;

      this.broadcast.goal.time = this.auxDay * 24 + this.auxHour;
      this.timeError = this.broadcast.goal.time > (90 * 24)
    }
  },
  watch: {
    audiences:{
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0 ){
          this.auxAudience = this.broadcast?.audience?.audience ? newVal.filter(a=> a._id == this.broadcast?.audience?.audience).length>0 ? newVal.filter(a=> a._id == this.broadcast?.audience?.audience)[0] : null : null
          // eslint-disable-next-line no-console
          console.log(this.broadcast?.excludeAudience?.audience)
          this.auxExcludeAudience = this.broadcast?.excludeAudience?.audience ? newVal.filter(a=> a._id == this.broadcast?.excludeAudience?.audience).length > 0 ? newVal.filter(a=> a._id == this.broadcast?.excludeAudience?.audience)[0] : null : null;
        } 
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-sm-8 d-flex">
        <div class="card w-100">
          <div class="card-body" >
            <div class="row">
              <div class="col-sm-6">
                    <div class="mb-0">
                        <label for="promotionName">{{$t('broadcasts.name')}}*</label>
                        <input
                            id="promotionName"
                            v-model="broadcast.name"
                            name="name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('broadcasts.name_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.broadcast.name.$error}"
                        />
                        <div v-if="submitted && !$v.broadcast.name.required" class="invalid-feedback">
                          {{$t('broadcasts.name_required')}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-0">
                        <label for="promotionName">{{$t('broadcasts.description')}}*</label>
                        <input
                            id="promotionName"
                            v-model="broadcast.description"
                            type="text"
                            class="form-control"
                            :placeholder="$t('broadcasts.description_placeholder')"
                            :class="{ 'is-invalid': submitted && $v.broadcast.description.$error}"
                        />
                        <div v-if="submitted && !$v.broadcast.description.required" class="invalid-feedback">
                          {{$t('broadcasts.description_required')}}
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 d-flex">
        <div class="card w-100">
          <div class="card-body" >
            <div class="row">
              <div class="col-sm-12">
                  <div class="mb-0">
                      <label for="promotionName">{{$t('goals.goal')}}*</label>
                      <multiselect
                        :options="goals" 
                        v-model="auxGoal"
                        track-by="_id" 
                        label="name"
                        selectLabel=""
                        deselectLabel=""
                        :allowEmpty="false"
                        class="me-2"
                        @input="onGoalSelected"
                        :show-labels="false"
                        :disabled="broadcast.status && broadcast.status!='draft'"
                      />
                  </div>
                  <div class="d-inline-flex align-items-baseline mt-1" v-if="auxGoal?._id != 'none'">
                    {{$t('goals.goal_next')}} 
                    <b-form-input :disabled = "broadcast.status != 'draft'"  class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxDay" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                    {{$t('goals.goal_days')}}
                    <b-form-input :disabled = "broadcast.status != 'draft'" class="form-control form-control-sm ms-2 me-2" type="number" v-model="auxHour" style="width: 60px;" @input="onTimeChanged"></b-form-input>
                    {{$t('goals.goal_hours')}} 
                  </div>
                  <div v-if="submitted && timeError" class="invalid-feedback" style="display: block;">
                    {{ $t('goals.goal_time_error') }}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8 col-sm-12">
      <div class="card">
        <div class="card-body" >
          <div class="row">
            <label class="card-title">{{$t('broadcasts.send')}}</label>
            <div class="col-sm-4">
              <div class="mb-0">
                <label class="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="audience"
                    id="buycurrencyoption1"
                    class="card-radio-input"
                    :checked="broadcast.audience.type == 'all'"
                    @input="onAudienceTypeSelected('all')"
                    :disabled="broadcast.status && broadcast.status!='draft'"
                  />
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-account-group font-size-24 text-primary align-middle me-2"></i>
                      <span>{{$t('broadcasts.audience_all')}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div class="col-sm-4">
              <div class="mb-0">
                <label class="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="audience"
                    id="buycurrencyoption2"
                    class="card-radio-input"
                    :checked="broadcast.audience.type == 'audience'"
                    @input="onAudienceTypeSelected('audience')"
                    :disabled="broadcast.status && broadcast.status!='draft'"
                  />
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-set-center font-size-24 text-primary align-middle me-2"
                      ></i>
                      <span>{{$t('broadcasts.audience')}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mb-0">
                <label class="card-radio-label mb-2">
                  <input
                    type="radio"
                    name="audience"
                    id="buycurrencyoption3"
                    class="card-radio-input"
                    :checked="broadcast.audience.type == 'list'"
                    @input="onAudienceTypeSelected('list')"
                    :disabled="broadcast.status && broadcast.status!='draft'"
                  />
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-book-account-outline font-size-24 text-primary align-middle me-2"
                      ></i>
                      <span>{{$t('broadcasts.audience_import')}}</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-6" v-if="broadcast.audience?.type == 'audience'">
              <label class="mb-1">{{$t('broadcasts.audience')}}*</label>
                <multiselect
                  :options="audiences" 
                  v-model="auxAudience"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :allowEmpty="false"
                  class="form control"
                  :placeholder="$t('broadcasts.audience_placeholder')"
                  @input="onAudienceSelected"
                  :disabled="broadcast.status && broadcast.status!='draft'"
                />
                <div class="invalid-feedback" v-if="submitted && !auxAudience" style="display: block !important">
                {{$t('broadcasts.audience_required')}}
                </div>
            </div>
            <div class="col-sm-6" v-if="broadcast.audience?.type == 'list'">
              <label class="mb-1">{{$t('contact_lists.title')}}*</label>
                <multiselect
                  :options="contactLists" 
                  v-model="auxContactList"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :allowEmpty="false"
                  class="form control"
                  :placeholder="$t('broadcasts.contact_list_placeholder')"
                  @input="onContactListSelected"
                  :disabled="broadcast.status && broadcast.status!='draft'"
                />
                <div class="invalid-feedback" v-if="submitted && !auxContactList" style="display: block !important">
                {{$t('broadcasts.contact_list_required')}}
                </div>
            </div>
            <div class="col-sm-6">
              <label class="mb-1">{{$t('broadcasts.exclude_audience')}}</label>
              <multiselect
                  :options="audiences" 
                  v-model="auxExcludeAudience"
                  track-by="_id" 
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  class="form control"
                  :placeholder="$t('broadcasts.audience_placeholder')"
                  @input="onExlcudeAudienceSelected"
                  :disabled="broadcast.status && broadcast.status!='draft'"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 d-flex" v-if="broadcast?.status=='draft'">
      <div class="card w-100">
        <div class="card-body">
          <div class="d-flex flex-wrap">
            <h5 class="card-title mb-3 me-2">{{$t('broadcasts.estimated_recipients')}}</h5>
            <div class="btn-group ms-auto">

            </div></div>
            <div class="d-flex flex-wrap">
              <div>
                <p class="text-muted mb-1">{{$t('broadcasts.estimated_recipients_subtitle')}}</p>
                <h4 class="mb-3">{{`${audience_size}/${totalCount}` }}</h4>
              </div>
              <b-spinner
										v-if="isCustomerCountLoading"
										class="m-2"
										variant="primary"
										role="status">
									</b-spinner>
            </div>
            <i class="bx bx-group display-4 text-light" style="position: absolute;right: 5px;bottom: 5px;"></i>
          </div>
      </div>
    </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body" >
            <div class="row">
              <label class="card-title">{{$t('broadcasts.variants')}}</label>
              <EmptyList :config="empty_config" v-if="broadcast.variants.length == 0" @onButtonClicked="onNewVariantClicked" class="mt-3"/>
              <button class="btn btn-link text-end" v-if="broadcast.variants.length > 0 && (!broadcast.status || broadcast.status=='draft')" @click="onNewVariantClicked" style="margin-top: -20px;">+ {{ $t('broadcasts.variants_new')}}</button>
              <table class="table table align-end" v-if="broadcast.variants.length > 0">
                <thead class="table-light">
                  <tr>
                    <th>{{$t('broadcasts.name')}}</th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(variant, index) in broadcast.variants" :key="variant.name">
                    <td class="align-middle fw-bold text-decoration-underline interact" v-on:click="onEditVariantClicked(variant)">
                      {{ variant.name }}
                    </td>
                    <td class="text-end align-middle">
                      <i class="fas fa-clone text-info me-2 interact" v-on:click="onCloneVariantClicked(variant)" v-if="!broadcast.status || broadcast.status=='draft'"/>
                      <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditVariantClicked(variant)" v-if="!broadcast.status || broadcast.status!='finished'"/>
                      <i class="fas fa-trash-alt text-danger me-2 interact" v-on:click="onRemoveVariantClicked(index)" v-if="!broadcast.status || broadcast.status=='draft'"/>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 d-flex">
        <div class="card w-100">
          <div class="card-body" >
            <div class="row">
              <label class="card-title">A/B Testing</label>
              <div v-if="broadcast.variants.length < 2" class="col mt-4 bg-info bg-gradient text-white p-3 text-center ms-3 me-3">
                <i class="mdi mdi-alert-circle-outline"></i> {{$t('broadcasts.ab_testing_subtitle')}}
              </div>
              <div v-if="broadcast.variants.length > 1" class="mt-3">
                <b-form-input :disabled="broadcast.status && broadcast.status!='draft'" v-model="broadcast.settings.test_ratio" type="range" :min="0" :max="100" step="1" class="w-100" />
                <div class="flex-row">
                  <label class="me-auto">Test: {{ `${broadcast.settings.test_ratio}%`  }}</label>
                  <label style="float: right;">{{$t('broadcasts.ab_testing_remaining') }} {{ `${100 - broadcast.settings.test_ratio}%`  }}</label>
                </div>
              </div>
              <div v-if="broadcast.variants.length > 1" class="mt-2">
                <div class="d-inline-flex w-100 align-items-baseline">
                  <label class="me-2 small">{{$t('broadcasts.winner_1')}}</label>
                  <multiselect
                    :options="times" 
                    v-model="broadcast.settings.testing_duration"
                    selectLabel=""
                    deselectLabel=""
                    :allowEmpty="false"
                    class="me-2"
                    style="width: 45px !important;"
                    :show-labels="false"
                    :disabled="broadcast.status && broadcast.status!='draft'"
                  />
                  <label class="me-2 small">{{$t('broadcasts.winner_3')}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
					v-model="showVariant"
					id="modal-xl23"
					size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          class="variant-email"
          >
          <template #modal-title>
            <input class="form-control" v-model="variant.name" style="font-weight: bold;"/>
          </template>
          <template #modal-footer>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button variant="secondary" @click="onCancelVariantClicked()">
              {{ $t('common.cancel') }}
            </b-button>
            <!-- Button with custom close trigger value -->
            <b-button variant="outline-primary" @click="onTestMessageClicked()">
              {{$t('broadcasts.test_message')}}
            </b-button>
            <b-button variant="primary" @click="onConfirmVariantClicked()">
              {{ $t('common.confirm') }}
            </b-button>
          </template>

          <EmailConfig v-if="channel=='email'" :email="variant.config" ref="emailConfigComponent" :variant="variant" ></emailConfig>
          <SmsConfig  v-if="channel=='sms'" ref="smsConfigComponent" :variant="variant" ></smsConfig>
          <AppPushConfig v-if="channel=='app_push'" ref="appPushConfigComponent" :variant="variant"/>
          <WebPushConfig v-if="channel=='web_push'" ref="webPushConfigComponent" :variant="variant"/>
      </b-modal>
    <div class="row mt-4">
				<div class="col-sm-6">
				</div>
				<!-- end col -->
				<div class="col-sm-6">
					<div class="text-end">
						<b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t("common.cancel")}}</b-button>
						<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmClicked">{{$t("common.save")}}</b-button>
					</div>
				</div>
				<!-- end col -->
		</div>
  </Layout>
</template>